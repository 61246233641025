



































































































































































































































































































































































































































































































































































































































































































@import '../../assets/css/global.css';
@import "../../assets/css/antd.css";
#voltage-analysis-chart {
	width: 100%;
	margin: auto;
	height: 750px;
	overflow: hidden;
	/* background: #000; */
}
